"use client";
import { useEffect, useState } from "react";

/**
 * 用于判断是否处在客户端，而非服务端，比typeof window更准确，typeof window在水合状态也是true
 * @returns boolean
 */
export default function useMounted() {
    const [mounted, setMounted] = useState<boolean>(false);

    useEffect(() => {
        setMounted(true);
    }, []);
    return mounted;
}
