"use client"; // Error components must be Client Components
import * as Sentry from "@sentry/browser";
import { useEffect } from "react";
import CustomError500 from "../components/CustomError/500";
import useMounted from "../hooks/useMounted";

export default function Error({
    error,
    reset,
}: Readonly<{
    error: Error;
    reset: () => void;
}>) {
    const mountd = useMounted();
    useEffect(() => {
        error && Sentry.captureException(error);
    }, [error]);

    return mountd && <CustomError500 error={error} reset={reset} />;
}
