import { FmImage } from "@/ui-component";
import styles from "../error.module.scss";

export default function ErrorImage({ type }: { type: string }) {
    const notfoundImg =
        "https://df5apg8r0m634.cloudfront.net/images/2024/0626/ad05346c03357034a94bfff39a5d25a8.webp";

    const errorImg =
        "https://df5apg8r0m634.cloudfront.net/images/2024/0702/6ca8f39df13a555e042a00761a44dfaf.webp";

    const img = type === "404" ? notfoundImg : errorImg;
    return (
        <div className={styles["e_img"]}>
            <FmImage image={img} />
        </div>
    );
}
