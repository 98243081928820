"use client";
import styles from "../error.module.scss";
import classNames from "classnames";
import ErrorImage from "../components/ErrorImage";
import { ReactNode } from "react";

export default function ErrorMain({
    children,
    type,
}: {
    children: ReactNode;
    type: string;
}) {
    return (
        <div className={classNames(styles["text-center"])}>
            <div className={styles["error-main"]}>
                {children}
                <ErrorImage type={type} />
            </div>
        </div>
    );
}
