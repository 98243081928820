const getPageDataProps = (
    layoutData: { pageData: any[] },
    componentName: string,
) => {
    const component = layoutData?.pageData?.find(
        (item) => item.name === componentName,
    );
    return component?.props;
};

export default getPageDataProps;
