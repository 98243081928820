"use client";
import { useTranslation } from "@/src/i18n/client";
import { useEffect, useState } from "react";
import styles from "../error.module.scss";
import classNames from "classnames";
import ErrorMain from "../components/ErrorMain";
import { usePathname } from "next/navigation";
import { prometheusAlert } from "@/src/models/common";

export default function Client500({
    error,
    reset,
}: {
    error: Error;
    reset: () => void;
}) {
    const { t } = useTranslation("common");
    const pathname = usePathname() ?? "";
    const [currentError, setCurrentError] = useState<Error>();
    const title = t(`common:ErrorPage.500.p1`);
    const desc_1 = t(`common:ErrorPage.500.p2`);
    const desc_2 = t(`common:ErrorPage.500.p3`);
    const desc_3 = t(`common:ErrorPage.500.p4`);

    useEffect(() => {
        setCurrentError(error);
        prometheusAlert({
            path: pathname,
            host: window.location.host,
            statusCode: 500,
            error: error.message,
        });
    }, [error, pathname]);
    return (
        <ErrorMain type="500">
            <div>
                <p
                    className={classNames(
                        styles["error-statusCode"],
                        styles["text-center"],
                    )}
                >
                    Error
                </p>
                <div className={styles["tip"]}>
                    {currentError && currentError.message}
                </div>
            </div>
            <div className={styles["tip"]}>{title}</div>
            <div className={styles["b-text"]}>
                <div>{desc_1}</div>
                <div>
                    <div onClick={reset} className={styles["href_a"]}>
                        {desc_2}
                    </div>
                    {desc_3}
                </div>
            </div>

            <p className={styles["more-info"]}>
                {currentError && currentError.stack}
            </p>
        </ErrorMain>
    );
}
