"use client";
import {
    CommonProvider,
    FilterConditionProvider,
    PageLayoutProvider,
    UserInfoProvider,
} from "@/src/provider";
import Client500 from "./client";
import { LANGUAGE_NAME } from "@/config/constConf";
import { getCookieItem } from "@/lib/commonService";
import { getCMSDataClient } from "@/src/models/cmsClient";
import { useSearchParams } from "next/navigation";
import { useState, useEffect, useMemo } from "react";
import getPageDataProps from "@/src/utils/getCMSPageDataProps";
import Header from "../../Header";

export default function CustomError500({
    error,
    reset,
}: {
    error: Error;
    reset: () => void;
}) {
    const [pageLayoutData, setPageLayoutData] = useState<any>();
    const [commonParams, setCommonParams] = useState<any>();
    const searchParams = useSearchParams();
    useEffect(() => {
        const siteId = self.getSiteId();
        const site = self.getSite();
        const is_cms = self.getSiteConfiguration("is_cms");
        const language = getCookieItem(LANGUAGE_NAME) ?? "en";
        setCommonParams({ siteId, site, is_cms, language });
        const pageType = searchParams.get("pageType");
        getCMSDataClient({ pageType }).then((res: any) => {
            setPageLayoutData(res);
        });
    }, [searchParams]);
    const topNotice = useMemo(() => {
        return getPageDataProps(pageLayoutData, "H5TopNotice");
    }, [pageLayoutData]);
    const H5Circle = useMemo(() => {
        return getPageDataProps(pageLayoutData, "H5Circle");
    }, [pageLayoutData]);
    const H5Notice = useMemo(() => {
        return getPageDataProps(pageLayoutData, "H5Notice");
    }, [pageLayoutData]);

    return (
        commonParams && (
            <CommonProvider
                host={self.location.host}
                siteId={commonParams.siteId}
                site={commonParams.site}
                is_cms={commonParams.is_cms}
                lang={commonParams.language}
            >
                <UserInfoProvider>
                    <FilterConditionProvider>
                        {pageLayoutData && (
                            <PageLayoutProvider {...pageLayoutData}>
                                <Header
                                    topNotice={topNotice}
                                    page_name={"dynamic_page"}
                                    circle={H5Circle}
                                    H5Notice={H5Notice}
                                    // route-pathname是一个自定义的header属性, 在middleware.ts塞入，用于判断当前页面的路由
                                    hiddenWhenNoFixed={false} // 设置在商品详情页header在没有被fixed时会被隐藏
                                />
                                <Client500 error={error} reset={reset} />
                            </PageLayoutProvider>
                        )}
                    </FilterConditionProvider>
                </UserInfoProvider>
            </CommonProvider>
        )
    );
}
